import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonComponent } from '../../../shared/button/button.component';

@Component({
  selector: 'app-success-modal',
  standalone: true,
  imports: [TranslateModule, CommonModule, ButtonComponent],
  templateUrl: './success-modal.component.html',
  styleUrl: './success-modal.component.scss',
})
export class SuccessModalComponent {
  headingContent: string = '';
  contentName: string = '';
  isEmployeeId: boolean = false;
  isLoginSuccess: boolean = false;
  codeContent:string = '';;

  constructor(
    public config: DynamicDialogConfig,
    private router: Router,
    private ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    if (this.config.data) {
      this.headingContent = this.config.data.headingContent;
      this.contentName = this.config.data.contentName;
      this.isEmployeeId = this.config.data.isEmployeeId;
      this.isLoginSuccess = this.config.data.isLoginSuccess;
      // this.codeContent = this.config.data.codeContent;
    }
  }

  onLogin() {
    this.ref.close();
    this.router.navigate(['/login']);
  }
}
