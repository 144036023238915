<div class="success-container">
  <img class="success-img" src="assets/images/success-img.svg" />
  <p class="content">
    <span *ngIf="isEmployeeId">{{ "EMPLOYEE_ID" | translate }} </span>
    <span *ngIf="codeContent">{{codeContent}}</span>
    <span>{{ contentName }} </span>{{ headingContent | translate }}
  </p>
  <div *ngIf="isLoginSuccess" class="content w-[90%]">
    <p>{{ "LOGOUT_SUCCESS.FIRST" | translate }}</p>
    <p class="login-txt mb-5">{{ "LOGOUT_SUCCESS.SECOND" | translate }}</p>
    <app-button [name]="'Login'" [btnClass]="'login-btn'" (onClick)="onLogin()" ></app-button>
  </div>
</div>
